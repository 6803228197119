@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');





/* ABOUT US */
.about-background{
  background-color: #F6BC65;
}

.about-photo{
  padding: 20px;
  padding-bottom: 0px;
  width: 100%;
  /* max-width: 50%;
  max-height: 50%;
  width: auto;
  height: auto; */
}

.about-name{
  font-weight: bold;
  font-size: 30px;
  color: white;
  padding-bottom: 5px;
  justify-content: center;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  text-shadow: 2px 2px #ff5757;
  text-align: center;
}

.about-title{
  font-weight: bold;
  font-size: 20px;
  color: white;
  padding-bottom: 10px;
  justify-content: center;
  display: flex;
  margin-top: 0px;
  text-shadow: 2px 2px #ff5757;
  text-align: center;
}

.about-row{
  display: flex;
  flex-wrap: wrap;
}

.about-col{
  flex: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  /* For Desktop */
.about-col{
  flex: 33.3%;
  max-width: 33.3%;
}
}
/*   NAVBAR   */

.navbar {
  background-color: #C75146;
  border-bottom: 3px solid #000000;
  height: auto;
  min-height: 50px;
}

.navTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  height: 40px;
}

.countBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.pizzaCount {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
  text-shadow: 2px 2px #81171B;
  margin: 0;
  padding: 4px;
  transition: transform 0.3s ease;
  line-height: 1;
}

.milestone-popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #F6BC65;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-shadow: 1px 1px #81171B;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: popIn 0.5s ease-out forwards;
  z-index: 1000;
  white-space: nowrap;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -5px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* Add a subtle pulse animation when the count changes */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pizzaCount.updating {
  animation: pulse 0.5s ease-in-out;
}

@media(min-width: 768px) {
  /* For Desktop */
  .navTop {
    height: 50px;
    padding: 4px 24px;
  }

  .pizzaCount {
    font-size: 24px;
  }

  .image-link {
    width: 32px;
  }

  .git-link {
    width: 32px;
    height: 32px;
  }

  .navLink {
    font-size: 18px;
    padding: 6px 12px;
  }

  .nav-flex {
    height: 40px;
  }

  .image-link:hover {
    transform: rotate(360deg);
  }

  .git-link:hover {
    transform: scale(1.1);
  }

  .navLink:hover {
    color: #F6BC65;
  }
}

.navIcon{
  float: left;
}

.image-link{
  width: 24px;
  transition: transform 1s;
  margin: 0;
  padding: 0;
}

.git-link {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.NavColor {
  background-color: #C75146;
  border-bottom: #000000;
  border-style: solid;
  border-width: 5px;

 
}

.NavList{
  color: pink;
  list-style: none;
  display: inline;
}



.navLink{
  color: white;
  text-shadow: 2px 2px #81171B;
  font-size: 16px;
  font-weight: bold;
  padding: 4px 8px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

.nav-flex{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px 0;
  height: 32px;
}



.pizzaBox{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
}

.Pizzas-Color {
  background-color: #F6BC65;
}




/* VIDEOS PAGE */

.video-search-box{ 
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 70px;
  padding-bottom: 40px;
  
}

.video-search-input{
  padding-left: 15px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  border: 3px solid #000000 ;
  width: 125px;

}
.Videos-Color {
  background-color: #F6BC65;
  
}

.videos-flexbox {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default 1 column for mobile */
  gap: 2rem;
  padding: 2rem;
  justify-items: center;
}

@media(min-width: 768px) {
  /* For Desktop */
  .videos-flexbox {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop */
    max-width: 1500px; /* Prevent excessive stretching on very wide screens */
    margin: 0 auto; /* Center the grid */
  }
}

.video {
  width: 100%;
  max-width: 500px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video-title-wrapper {
  height: 5em; /* Adjusted for the larger font size */
  margin-top: 0.5rem;
  overflow: hidden;
}

.video-title {
  font-weight: bold;
  font-size: 30px;
  color: white;
  margin: 0;
  padding-top: 10px;
  font-family: "Poppins", sans-serif;
  text-shadow: 2px 2px #ff5757;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Reduced to 2 lines since font is larger */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: center;
}

.video-search-box {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.video-search-input {
  width: 100%;
  max-width: 500px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pizzavideo{
  display: flex;
  justify-content: center;
}

.pizza-photo-wrapper {
  position: relative;
  width: 40%;
  max-width: 400px;
  margin: 10px;
  aspect-ratio: 1;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.pizza-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

@media(min-width: 768px) {
  .pizza-photo-wrapper {
    width: 30%;
  }
  
  .pizza-photo:hover {
    transform: scale(1.05);
  }
}

.pizza-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #F6BC65;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.pizza-container > div {
  margin: 10px;
  padding: 20px;

}




.quote-text {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  text-align: center;
  color: white;
  text-shadow: 2px 2px #ff5757;
  font-weight: bold;
  padding: 20px;
  width: 70%;
  margin: 0 auto 20px;
  display: block;
  line-height: 1.2;
}


@media(min-width: 768px) {
  /* For Desktop */
  .quote-text {
    width: 800px;
    font-size: 35px;
  }
}


/* HOME PAGE */

.background{
  background-color: #F6BC65;
}


.down-arrow{
  width: 40px;
  height: auto;
  margin: auto;
  display: block;
}

.home-text{
  background-color:rgb(0, 0, 0);
  color: white;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  place-items: center;
  align-content: center;
  
}

.home-div{
  
  display: grid;
  place-items: center;
  min-height: 100vh;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
}


.videoHome{
  position: fixed;
  z-index: -1;
}


@media (min-aspect-ratio: 16/9) {
  .videoHome {
      width:100%;
      height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .videoHome { 
      width:auto;
      height: 100%;
  }
}

.home-div1{
  
  display: grid;
  place-items: center;
  align-content: center;
  
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Poppins", sans-serif;
  width: 350px;
  margin-inline: auto;
  border: 3px solid #000000;
}


@media(min-width: 768px) {
  /* For Desktop */
 .home-div1{
  width: 500px;
}
} 

.home-about{
  
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Poppins", sans-serif;
  width: 350px;
  margin-inline: auto;
  color: beige;
  text-shadow: 1px 1px #81171B;
  font-weight: bold;
  font-size: 18px;
  
}


 @media(min-width: 768px) {
  /* For Desktop */
 .home-about{
  font-size: 22px;
  width: 550px;
}
} 

.home-title-box{
  display: grid;
  place-items: center;
  align-content: center;
  padding-top: 10%;
}

.home-title{
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: beige;
  text-shadow: 3px 3px black;
  text-align: center;
}

.home-subheader{
  font-size: 28px;
  color:beige;
  text-align: center;
  text-shadow: 2px 2px black;
}

.home-paragraph{
  font-size: 20px;
  color:rgb(255, 255, 255);
  text-align: center;
  margin-top: 0;
  width: 300px;
  text-shadow: 1px 1px black;
}


@media(min-width: 768px) {
  /* For Desktop View */
.home-paragraph{
  width: 450px; 
  font-size: 22px;
}
}


.youtube-icon{
  display: block;
  width: 150px;
  height: auto;
  margin:auto;

}

.home-tips-box{
  display: block;
  margin: auto;
  background-color: beige;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 140px;
  color: black;
  font-size: 28px;
  margin-top: 40px;
  border: 3px solid #000000;
  padding: 18px;
  
}

@media(min-width: 768px) {
  /* For Desktop */
.home-tips-box:hover{
  background-color: rgba(0, 0, 0, 0.237);
  color: beige;
}
}

.red-button{
  display: block;
  margin: auto;
  background-color: #CB342A;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: center;
  width: 110px;
  color: beige;
  margin-top: 30px;
  border: 3px solid #000000;
  padding-left: 20px;
  padding-right: 20px;
  
}

@media(min-width: 768px) {
  /* For Desktop */
.red-button:hover{
  background-color: beige;
  color: #CB342A;
}
}



.home-icon{
  height: auto;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.home-image{
  max-width: 100%;
  height: auto;
  z-index: 0;
  position: relative;
}

.homeImage{
  background-position: center;
  width: 100%;
  height: auto;
  display: grid;
  place-items: center;
}




/* NO PAGE FOUND  */

.no-page {
  background-color: black;
  color: white;
}

.no-page-cat{
  margin-left: 25%;
  margin-right: 25%;
  padding-top: 25%;
  padding-bottom: 25%;
  width: 50%;
  height: auto;
  align-items: center;
}

.no-page-text{
  text-align: center;
  color: white;
  size: 20px;
  font-family: 'Poppins', sans-serif;
}

/* CUSTOMIZEDACCORDION  */

 /* .accord-di{
  margin-top: 20px;
  margin-bottom: 20px
}  */


/* .accord-div{
  width: 90%;
} */


.accordDiv1{
  width: 20%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  position: relative;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  width: 60%;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);

}

@media(min-width: 768px) {
  /* For Desktop */
  .accordDiv1{
  width: 400px;
  
  }
  }

/*  TIPS PAGE  */

.faq-header{
  font-family: 'Poppins', sans-serif;
  font-size: 90px;
  text-align: center;
  color: white;
  text-shadow: 6px 6px #ff5757;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.faq-sub-header{
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  text-align: center;
  color: white;
  text-shadow: 2px 2px #ff5757;
  font-weight: bold;
  margin-top: 0;
  padding-top: 20px;
  margin-bottom: 10px;
}

.tips-footer{
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #000000;
  width: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 60px;
  padding-bottom: 20px;
}


/* PIZZA MAP */

.leaflet-container{
  height: 500px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
}

@media(min-width: 768px) {
  /* For Desktop */
.leaflet-container{
width: 600px;
}
}

.map{
  height: 200px;
  width: 200px;

}


.map-header{
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  text-align: center;
  color: white;
  text-shadow: 2px 2px #ff5757;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #F6BC65;
}

/* Map + Side List Container */
.map-list-box{
  display: flex;
  flex-wrap: wrap;
}

/* Main Map Container */
.wide-map{ 
flex: 70%;
background-color: #F6BC65;
}

/* Side List Contaier */
.side-list{
  flex: 30%;
  background-color: #F6BC65;
}

/* Searching Box for Map */
.map-search-box{ 
  align-items: center;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  /* background-color:  #F6BC65; */
  z-index: 1000;
  position: absolute;
  width: 50vw;
}

.map-container{
  position: relative;
  justify-content: center;
  text-align: center;
  align-items: center;
}

/* REVIEWS */

.review-header{
  display: flex;
  border-bottom: 5px solid #000000;
  margin: auto;
}

.review-header > div {
 /* Copied most of this css from FAQ sub-header, temporary */
  margin: auto;
  padding: 10x;
  font-size: 30px;
  font-weight: bold;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: white;
    text-shadow: 2px 2px #ff5757;
    font-weight: bold;
    
}

/* Review Component */
.review-card {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.review-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.review-header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
}

.restaurant-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #C75146;
  grid-column: 1 / -1;
}

.restaurant-location {
  color: #666;
  font-size: 0.9rem;
}

.restaurant-score {
  font-weight: bold;
  color: #F6BC65;
  text-align: right;
}

.restaurant-price {
  color: #81171B;
  text-align: right;
}

.review-details h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #333;
}

.review-details span {
  font-weight: normal;
  color: #666;
}

/* PizzaMap Component */
.map-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjust based on your navbar height */
  background-color: #F6BC65;
}

.map-header {
  text-align: center;
  color: white;
  text-shadow: 2px 2px #81171B;
  font-size: 2rem;
  margin: 1rem 0;
  font-family: "Poppins", sans-serif;
}

.map-search-box {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.map-list-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  padding: 0 1rem 1rem;
}

@media (min-width: 768px) {
  .map-list-box {
    flex-direction: row;
  }
  
  .wide-map {
    flex: 2;
    height: 70vh;
  }
  
  .side-list {
    flex: 1;
    overflow-y: auto;
    max-height: 70vh;
    padding: 0 1rem;
  }
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 3px solid #81171B;
}

/* Calculator Component */
.calculator-title {
  color: white;
  text-shadow: 2px 2px #81171B;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.recipe-results {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.recipe-results p {
  margin: 0.5rem 0;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
}

.recipe-results strong {
  color: #C75146;
}

/* Tips Component */
.background {
  background-color: #F6BC65;
  min-height: calc(100vh - 60px);
  padding: 2rem 1rem;
}

.faq-header {
  color: white;
  text-shadow: 2px 2px #81171B;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
}

.faq-sub-header {
  color: white;
  text-shadow: 2px 2px #81171B;
  font-size: 1.75rem;
  text-align: center;
  margin: 2rem 0;
  font-family: "Poppins", sans-serif;
}

.tips-footer {
  text-align: center;
  margin-top: 3rem;
  padding: 1rem;
  color: white;
  font-family: "Poppins", sans-serif;
}

.tips-footer h2 {
  font-size: 1.25rem;
  font-weight: normal;
  text-shadow: 1px 1px #81171B;
}

.tips-footer a {
  color: white;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.tips-footer a:hover {
  color: #81171B;
}

/* CustomizedAccordion Component */
.accordion-container {
  max-width: 800px;
  margin: 0 auto;
}

.accordion-item {
  margin-bottom: 1rem;
}

.accordion-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .faq-header {
    font-size: 2rem;
  }

  .faq-sub-header {
    font-size: 1.5rem;
  }

  .tips-footer h2 {
    font-size: 1rem;
  }

  .accordion-container {
    padding: 0 1rem;
  }
}

/* Home Component */
.home-container {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.videoHome {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.home-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.home-title {
  color: white;
  text-shadow: 3px 3px #81171B;
  font-size: 4rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.home-div1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.home-subheader {
  color: white;
  text-shadow: 2px 2px #81171B;
  font-size: 3rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1.5rem;
}

.home-paragraph {
  color: white;
  font-size: 1.25rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}

.youtube-link {
  text-decoration: none;
  display: block;
  text-align: center;
}

.red-button {
  display: inline-block;
  background-color: #C75146;
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  text-shadow: 2px 2px #81171B;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.red-button:hover {
  transform: translateY(-2px);
  background-color: #81171B;
}

.tips-link {
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 2rem;
}

.home-tips-box {
  display: inline-block;
  background-color: #F6BC65;
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  text-shadow: 2px 2px #81171B;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0;
}

.home-tips-box:hover {
  transform: translateY(-2px);
  background-color: #e5a952;
}

.down-arrow {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.about-section {
  padding: 3rem 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.home-about {
  color: white;
  font-size: 1.25rem;
  line-height: 1.8;
  font-family: "Poppins", sans-serif;
  text-align: justify;
  margin-top: 2rem;
}

.homeImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.7);
}

@media (max-width: 768px) {
  .home-title {
    font-size: 2.5rem;
  }

  .home-subheader {
    font-size: 2rem;
  }

  .home-paragraph {
    font-size: 1rem;
  }

  .red-button,
  .home-tips-box {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }

  .home-about {
    font-size: 1rem;
  }

  .home-div1 {
    padding: 1rem;
  }
}

/* Dough Calculator Styles */
.dough-calculator {
  background-color: #F6BC65;
  min-height: 100vh;
  padding: 20px;
}

.dough-calculator .MuiCard-root {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dough-calculator .MuiSlider-root {
  color: #F6BC65;
}

.dough-calculator .MuiSwitch-colorPrimary.Mui-checked {
  color: #F6BC65;
}

.dough-calculator .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #F6BC65;
}

.dough-calculator .MuiTableRow-root:last-child td {
  border-bottom: none;
}

.dough-calculator .MuiTypography-h4 {
  color: #333;
  margin-bottom: 24px;
}

.dough-calculator .MuiTypography-h6 {
  color: #555;
  margin-top: 32px;
}

@media(min-width: 768px) {
  .git-link:hover {
    transform: scale(1.1);
  }
}

.expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s ease-in;
}

.expanded-image-container {
  max-width: 70%;
  max-height: 70vh;
  animation: scaleIn 0.3s ease-out;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.expanded-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  max-height: 60vh;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}